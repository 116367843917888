import React from "react";
import { DataWithPopover } from "src/app/nbsCockpit/components/nbsCockpitDataWithPopover";

export function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export const hoverDetails = (item: any, hoverItem: any) => {
    return (
        <DataWithPopover
            item={item}
            hoverItem={hoverItem}
        />
    )
}

export const verifyAndGetVertexPayload = (itemsData: any, itemData: any, prData:any) => {
    console.log(itemData)
    let data = {status: false, payload: {}};
    const isVertexCallRealtedDataExist = itemsData.filter((item=>item.CompCode.substring(0, 2).toLocaleUpperCase() === 'US' && item.Potaxcode === 'I1'));
    if(isVertexCallRealtedDataExist.length > 0){
        const date = new Date();
        const todayDate = `${date.getFullYear()+date.getMonth()+date.getDate()}`;
        const getflexibleCodeFields  = (item) => {
            console.log(item.PR_AccCategory)
            if(item.PR_AccCategory === 'K')
                return {
                    flexibleCodeFields : [
                        {
                            fieldId: "11", 
                            value: item?.VerCCCat ? item.VerCCCat : ""
                        }
                    ]
                }
            else if(item.PR_AccCategory === "")
                return {
                    flexibleCodeFields : [
                        {
                            fieldId: "12", 
                            value: item?.MaterialType ? item.MaterialType : ""
                        }
                    ]
                }
            else
                return {
                    flexibleCodeFields : [
                        {
                            fieldId: "13", 
                            value:  item?.VerCostObject ? item.VerCostObject : ""
                        }
                    ]
                }
        }
        const lineItems = isVertexCallRealtedDataExist.map((item)=>{
            return {
                lineItemNumber: item.PrItem,
                taxDate: todayDate,
                costCenter: item.Pr_CostCenter,
                generalLedgerAccount: item.Pr_GlAccount,
                projectNumber: " ",
                extendedPrice: item.PrPrice,
                purchase: {
                    value: item?.MatTaxCode ? item.MatTaxCode : ""
                },
                quantity: {
                    unitOfMeasure: item.PrQuantityUom,
                    value: item.PrQuantity
                },
                flexibleFields: getflexibleCodeFields(item)
            }
        });
        const payload = {
            purchaseMessageType: "PURCHASE_ORDER",
            documentNumber: itemData.PrNo,
            orderType: "EasyReq",
            locationCode: "5001",
            documentDate: todayDate,
            transactionType: "PURCHASE",
            deliveryTerm: "SUP",
            buyer:{
                company: "Nestle",
                division: itemData.CompCode,
                destination:{
                    streetAddress1: itemData.Name1,
                    city: itemData.City,
                    mainDivision: itemData.Region,
                    postalCode: itemData.Postalcode,
                    country: itemData.Country
                }
            },
            vendor:{
                vendorCode:{
                    value: itemData.Supplier
                },
                administrativeOrigin:{
                    streetAddress1: itemData?.VendStreet ? itemData.VendStreet : "",
                    city: itemData?.VendCity ? itemData.VendCity : "",
                    mainDivision: itemData?.VendRegion ? itemData.VendRegion : "",
                    postalCode: itemData?.VendPostCode ? itemData.VendPostCode : "",
                    country: itemData?.VendCountry ? itemData.VendCountry : ""
                },
                physicalOrigin:{
                    streetAddress1: itemData.Name1,
                    city: itemData.City,
                    mainDivision: itemData.Region,
                    postalCode: itemData.Postalcode,
                    country: itemData.Country
                }
            },
            lineItems:lineItems
        }
        console.log(payload)
        return {status: true, payload: payload}
    }
    console.log(data)
    return data
}
